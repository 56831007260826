import React from 'react'

export const GeneralIcon = () => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5 mr-1">
      <path fillRule="evenodd" clipRule="evenodd"
            d="m11.307 6.753-1.89.121L8.8 5.057c-.028-.083-.15-.074-.16.02l-.294 2.179-1.56 1.109c-.066.046-.038.14.047.149l1.617.13.672 1.612c.028.074.142.065.16-.02l.388-1.927 1.703-1.416c.056-.047.01-.15-.067-.14Zm-.312 7.63-2.475.169-.81-2.386c-.037-.106-.199-.097-.217.018l-.387 2.864-2.061 1.464a.108.108 0 0 0 .054.195l2.133.168.883 2.12c.045.097.189.088.216-.018l.504-2.536 2.232-1.863a.108.108 0 0 0-.072-.195Zm4.943-5.293 3.877-.26c.17-.01.255.195.114.298l-3.5 2.915-.794 3.978c-.028.159-.265.186-.33.028l-1.382-3.316-3.338-.261c-.16-.01-.218-.214-.085-.307l3.225-2.292.605-4.49c.028-.177.284-.205.34-.028l1.268 3.735Z"
            fill="currentColor"></path>
    </svg>
  )
}

export default GeneralIcon
